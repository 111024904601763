<template>
    <div>
        <!-- <loading v-model:active="isLoading"
		:can-cancel="true"
		:color="color"
		:loader="loader"
		:opacity="opacity"
		:on-cancel="onCancel"
		:is-full-page="fullPage"/> -->
        <div id="template_loader_indicator" class="blackground">
            <div class="template-loader-indicator"></div>
        </div>
        <div class="header fixed w-full z-10" id="navbar">        
            <MegaHeader /> 
        </div>
        <!-- Banner will appear only when user authenticated -->
        <!-- <div class="md:pt-0 xl:pt-24" v-if="isAuth"> 
          <BannerSection/>
          </div> -->
          <!-- Sidebar will appear only when user authenticated -->
        <div class="flex pt-40 xl:pt-24 main" v-if="user.authenticated && isNoSidebarPages()">
            <Sidebar class="fixed h-full md:mt-3 xl:mt-0" id="sidebar" :class="[sidebarMenuClass]" />
            <div :class="[sidebarMenuClass]"></div>

            <!-- ~~~~ -->
            <!-- mobile screen -->
            <div class="w-full block md:hidden">   
                <Mobile-Sidebar/>
            </div>
            <!-- ~~~~~~ -->
            <div class="router-container mt-16 md:-mt-10 xl:mt-5" :class="[containerClass]" id="container-box">
                <router-view/>               
                <SlideFeedbackSection/>                
                <Footer/>                 
            </div>
        </div>                  

       <!--  <div class="flex xl:justify-end 2xl:justify-center" v-if="isAuth">
            <div class=" hidden xl:block">
                <div class="max-w-6xl 2xl:max-w-full pl-customPadding">
                    <SlideFeedbackSection/>                
                </div>
                <div class="max-w-6xl 2xl:max-w-full xl:pl-customPadding 2xl:ml-customMargin">     
                    <Footer/> 
                </div>
            </div>
        </div> -->
        <!-- Non-authenticated view router -->
        <div v-if="!user.authenticated">            
            <router-view />
            <SlideFeedbackSection/>    
            <Footer />   
        </div>
        <Alert/>

    </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapState } from 'vuex'
// import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import MegaHeader from './components/MegaHeader.vue'
import BannerSection from './components/BannerSection.vue'
import Sidebar from './components/partial/Sidebar.vue'
import Alert from './components/partial/Alert.vue'
import MobileSidebar from './components/partial/MobileSidebar.vue'
import SlideFeedbackSection from './app/feedbackReviews/components/SlideFeedbackSection.vue'
import Footer from './app/footer/components/Footer.vue'
// import Footer from './components/partial/Footer.vue'


export default {
    data: function() {
      return {
        sidebarMenu: true,
        sidebarMenuClass: 'w-sideWidth 2xl:w-2/12 md:block hidden',
        containerClass: 'w-full md:w-sideWidth2 2xl:w-10/12',
        // footerClass: 'w-full pl-2 lg:pl-3 xl:pl-footerPadding 2xl:ml-0',
        // reviewClass: 'max-w-6xl 2xl:max-w-full pl-customPadding',
}
},
components: {
    MegaHeader, 
    BannerSection,   
    Sidebar,
    MobileSidebar,
    SlideFeedbackSection,
    Footer,
    Alert,
    // Loading,
},


computed: {
    ...mapGetters({
      user: 'auth/user',
  }),
  ...mapState([
    // 'isLoading',
    // 'color',
    // 'loader',
    // 'opacity',
    // 'fullPage',
    'isAuth',
    'isCollapse',
   ]),
  
}, 

  methods: {
    isNoSidebarPages() {
        var noSidebarPaths = ['/']
        if (noSidebarPaths.includes(this.$route.path)) {
            this.user.authenticated = false;
            return false
        }
        return true
    },
     collapseSidebar: function() {
             this.sidebarMenu = !this.sidebarMenu
             if (this.sidebarMenu){
                this.sidebarMenuClass = 'w-sideWidth 2xl:w-2/12 md:block hidden';
                this.containerClass = 'w-full md:w-sideWidth2 2xl:w-10/12';
                // this.footerClass = 'w-full xl:pl-footerPadding 2xl:ml-0';
                // this.reviewClass = 'max-w-6xl 2xl:max-w-full pl-customPadding';
             }
             else {
                this.sidebarMenuClass = 'w-16 mr-16 xl:mr-0 md:block hidden';
                this.containerClass = 'w-full lg:w-12/12';                
                // this.footerClass = 'w-full';                
                // this.reviewClass = 'max-w-6xl 2xl:max-w-full xl:pl-customCollapsePadding';                
             }
        }       
  },

  mounted() {

		// simulate AJAX
		setTimeout(() => {

		}, 1000)



        let hideScroll = window.pageYOffset;
        window.onscroll = function() {
            let currentScrollPos = window.pageYOffset;

            if (currentScrollPos < 3) {
                let navbar = document.getElementById("navbar");
                let sidebar = document.getElementById("sidebar");
                let hideBtns = document.getElementById("hideBtns");
                let titleFixed = document.getElementById("title_fixed");

                if (navbar) {
                    navbar.style.top = "0";
                }
                if (sidebar) {
                    sidebar.style.top = "117px";
                }
                if (hideBtns) {
                    hideBtns.style.display = "block";
                }
                if (titleFixed) {
                    titleFixed.style.top = "2.8rem";
                    titleFixed.style.position = "static";
                    titleFixed.style.boxShadow = "none";
                }

            } else {
                let navbar = document.getElementById("navbar");
                let sidebar = document.getElementById("sidebar");
                let hideBtns = document.getElementById("hideBtns");
                let titleFixed = document.getElementById("title_fixed");

                if (navbar) {
                    navbar.style.top = "-165px";
                }
                if (sidebar) {
                    sidebar.style.top = "0";
                }
                if (hideBtns) {
                    hideBtns.style.display = "none";
                }
                if (titleFixed) {
                    titleFixed.style.top = "0";
                    titleFixed.style.position = "sticky";
                    titleFixed.style.boxShadow = "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)";
                }
            }
        };

    },

};
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: 0.1s all ease-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

#navbar {
  transition: top 0.3s;
}

#sidebar {
  transition: top 0.3s;
}


.router-container{
    transition: 0.3s ease;
  }

.template-loader-indicator {
  border: 16px solid #ef4444;
  border-top: 16px solid #fff;
  border-radius: 50%;
  width: 136px;
  height: 136px;
  animation: spin 2s linear infinite;
  position: relative;
  top: 200px;
  margin: auto;
  
}
.blackground{
    position: fixed;
	z-index: 50;
	padding-top:100px;
	left: 0;
	top:0;
	width: 100%;
	height:100%;
	overflow: auto;
	background-color:rgba(0 ,0 ,0 ,0.4);
    display: none;
}
</style>