import store from '../store/store.js'

const beforeEach = ((to, from, next) => {
    store.dispatch('auth/checkTokenExists').then(() => {
        if (to.meta.guest) {
            next({ name: 'my-profile' })
            return
        }

        next()
    }).catch(() => {
        if (to.meta.needsAuth) {
            next({ name: 'Sign-In' })
            return
        }

        next()
    })
})

export default beforeEach
