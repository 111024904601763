export default {
  state: {
    showDeleteUser: false,
    hideDeleteUser: false,
    
},
   
  getters: {
    showDeleteUser(state) {
      return state.showDeleteUser
    },
    hideDeleteUser(state) {
      return state.hideDeleteUser
    },
  },
  mutations: {
    showDeleteUser(state, flag) {
      state.showDeleteUser = flag
    },
    hideDeleteUser(state) {
      state.showDeleteUser = false
    }
  },
}