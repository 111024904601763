export default [
    {
        path: "/block",
        name: "block",
        component: () => import('../components/blockLists.vue'),
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    {
        path: "/view-employer-lists",
        name: "view-employer-lists",
        component: () => import('../components/partials/employerLists.vue'),
        meta: { 
            guest: false,
            needsAuth: true,        
        }
    },
    
    
]