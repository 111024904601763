export const getUserListData = (state) => {
	return state.userList.data
}

export const getUserListLinks = (state) => {
	return state.userList.links
}

export const getUserListMeta = (state) => {
	return state.userList.meta
}

export const getMyRoleList = (state) => {
	return state.myRoles
}
export const getUserCurrentPage = (state) => {
	return state.userList.current_page;
}