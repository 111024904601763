<template>
    <div>
        <header class="border-b">
            <div class="md:flex justify-between px-2 md:px-6 bg-white mb-2 md:mb-0">
                 <div class="p-1">
                    <router-link to="/">
                        <img src="/images/smartRecruitNew.jpg" class="w-20 md:w-32 h-14 object-contain">
                    </router-link>
                </div>
                <div class="flex gap-4 items-center">
                    <div class="w-32">
                        <a :href="sekaiIchi" target="_blank" class="flex justify-center items-center rounded-full px-3 border hover:border-gray-400 bg-white">
                            <img src="/images/sekai-logo.png" class="bg-white rounded-full px-1 h-7 w-full object-contain p-0.5">
                        </a>
                    </div>                
                    <div class="w-32 relative">
                        <!-- group -->
                        <a :href="recruitUrl" target="_blank" class=" flex justify-center items-center rounded-full px-3 border hover:border-gray-400 bg-white">
                            <img src="/images/smartRecruitNew.jpg" class="bg-white rounded-full px-1 h-7 w-full object-contain p-0.5">
                        </a>
                    </div>
                    <div class="w-32">
                        <label @click="jiipaDropIt = !jiipaDropIt" class="cursor-pointer flex justify-center items-center rounded-full px-3 border hover:border-gray-400 bg-white">
                            <img src="/images/jiipa.png" class="bg-white rounded-full px-1 h-7 w-full object-contain p-1">
                        </label>
                        <button v-if="jiipaDropIt" @click="jiipaDropIt = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>
                        <div class="relative z-50" v-if="jiipaDropIt">
                            <ul class="absolute right-0 top-2 w-32 md:w-36 xl:w-48 bg-white space-y-1 text-sm rounded-md shadow-lg border" >
                                <div class="flex flex-col">
                                    <a :href="jiipaClientUrl" @click="jiipaDropIt = !jiipaDropIt" target="_blank" class="hover:bg-gray-200 pl-4 py-2 rounded-t-md">
                                   <!-- Client -->{{$t('client')}}
                                    </a>
                                    <a :href="jiipaAdminUrl" @click="jiipaDropIt = !jiipaDropIt" target="_blank" class="hover:bg-gray-200 pl-4 py-2">
                                        <!-- Super Admin -->{{$t('super-admin')}}
                                    </a>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="relative inline-flex h-7">
                        <svg class="w-1.5 absolute top-0 right-0 m-3 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#fff" fill-rule="nonzero"/></svg>
                        <select class="w-32 border border-gray-300 rounded-full text-white pl-4 pr-8 py-1 font-semibold text-xs hover:border-gray-400 focus:outline-none appearance-none bg-bgGray">
                            <option>Country{{$t('head-c')}}</option>
                            <option>India{{$t('e-tbl1-col3-row2-txt')}}</option>
                            <option>Japan{{$t('p-tbl2-col3-row1-txt')}}</option>
                            <option>USA{{$t('ed-cd4-txt5')}}</option>
                        </select>
                    </div> -->
                    <div class="relative inline-flex w-32">
                        <svg class="w-1.5 absolute top-0 right-0 m-3 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#fff" fill-rule="nonzero"/></svg>
                        <select class="w-full border border-gray-300 rounded-full text-white pl-4 pr-8 h-7 flex items-center font-semibold text-xs hover:border-gray-400 focus:outline-none appearance-none bg-bgGray" @change="languageHandler()" v-model="locale">
                            <!-- <option value="">{{ $t('head-l') }}</option> -->
                            <option :value="item.slug" v-for="(item, i) in languages" :key="i">{{item.name}}</option>
                        </select>
                    </div> 
                </div>          
            </div>
            
            <div class="flex justify-between gap-2 items-center px-3 xl:px-6 bg-bgGray border-b border-gray-200">
                <!-- <a v-for="(link, index) in headerLinks" :key="index" :href="link.link" class="my-auto" :class="{[link.class]:true}" target='_blank'  v-show="link.show" v-html="$t(link.name)"></a> -->
                <div class="flex justify-center w-full bg-bgGray py-2.5 px-3 xl:px-6 text-xs xl:text-sm font-semibold">
                    <div class="w-32 flex items-center justify-between">
                        <a :href="advSearch" target="_blank" class="hidden lg:block text-white flex items-center text-center justify-center hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-s')}}</a>
                        <div class="hidden lg:block border-r h-6"></div>
                    </div>

                    <div class="w-32 flex items-center justify-between">
                        <a :href="employerUrl" target="_blank" class="hidden lg:block text-white flex items-center text-center justify-center hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-e')}}</a>
                        <div class="hidden lg:block border-r h-6"></div>
                    </div>
                    <div class="w-40 flex items-center justify-between">
                        <a :href="jobSeekerUrl" target="_blank" class="hidden lg:block text-white flex items-center text-center justify-center  hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-js')}}</a>
                        <div class="hidden lg:block border-r h-6"></div>
                   </div>
                    <div class="w-36 flex items-center justify-between">
                        <a :href="titpUrl" target="_blank" class="hidden lg:block text-white flex items-center text-center justify-center hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('TITP')}}</a>
                        <div class="hidden lg:block border-r h-6"></div>
                    </div>
                    <div class="w-48 flex items-center justify-between">
                        <a :href="outSourcingrUrl" target="_blank" class="hidden lg:block text-white flex items-center text-center justify-center hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-ot')}}</a>
                        <div class="hidden lg:block border-r h-6"></div>
                    </div>
                    <div class="w-40 flex items-center justify-between">
                        <a :href="jobFairUrl" target="_blank" class="hidden lg:block text-white flex items-center text-center justify-center hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-jf')}}</a>
                        <div class="hidden lg:block border-r h-6"></div>
                    </div>
                    <div class="w-40 flex items-center justify-between">
                        <a :href="advertisementsUrl" target="_blank" class="hidden lg:block text-white flex items-center text-center justify-center hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-a')}}</a>
                        <div class="hidden lg:block border-r h-6"></div>
                    </div>
                    <div class="w-44 flex items-center justify-between">
                        <a :href="relocationUrl" target="_blank" class="hidden lg:block text-white flex items-center text-center justify-center hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-rs')}}</a>
                        <div class="hidden lg:block border-r h-6"></div>
                    </div>
                    <div class="w-40 flex items-center justify-between">
                        <a :href="dashboardHomeUrl" target="_blank" class="hidden lg:block text-white flex items-center text-center justify-center hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-d')}}</a>
                        <div class="hidden lg:block border-r h-6"></div>
                    </div>
                    <div class="w-40 lg:w-32 flex items-center justify-between" v-if="!user.authenticated">
                        <a :href="signUp" target="_blank" class="flex items-center text-center justify-center text-red-500 hover:text-white hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('su-title')}}</a>
                    </div>

                    <div class="w-60 lg:w-32 flex items-center justify-center space-x-3" v-if="user.authenticated">
                        <div class="relative cursor-pointer notification-bell" @click="bellNotificationHandler(true)">
                            <span class>
                                <svg class="fill-current p-1 text-gray-500 p-1 bg-gray-200 hover:bg-gray-300 rounded-full border border-gray-300" xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z" />
                                </svg>
                            </span>
                            <span v-if="bellNotifications.count > 0" class="absolute inset-x-3 -top-2 bg-red-700 rounded-full text-xs text-center text-white w-6" v-html="bellNotifications.count" />
                        </div>
                        <div class="flex items-center">
                            <button @click="toggleDropIt();" type="button" class="w-10 md:w-auto">
                                <img :src="this.$store.state.myprofile.img" class="bg-white w-8 h-8 border rounded-full object-cover">
                            </button>
                            
                            <button v-if="dropIt" @click="closeDropIt();" type="button" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-50"></button>

                            <!-- profile dropdown -->
                            <div class="relative z-50" v-if="dropIt">
                                <ul class="absolute -right-4 top-7 w-36 bg-white space-y-1 text-sm rounded-md shadow-lg border border-gray-100">
                                    <div class="flex flex-col">
                                        <router-link to="my-profile" @click="closeDropIt();" class="capitalize hover:bg-gray-200 pl-4 py-2 rounded-t-md"><!-- My Profile -->{{$t('sd-menu-title')}}</router-link>
                                        <router-link :to="{name:'dashboard'}" @click="closeDropIt();" class="capitalize hover:bg-gray-200 pl-4 py-2"> <!-- Home -->{{$t('head-d')}} </router-link>
                                        <span class="capitalize hover:bg-gray-200 pl-4 py-2 cursor-pointer" @click="$store.commit('showChangePasswordModel', true); closeDropIt();"> 
                                        <!-- Change Password -->{{$t('cp-col2-title')}}
                                         </span>

                                        <span class="px-3"><hr></span>                           
                                        <router-link to="#" v-on:click="logout(); closeDropIt();" class="capitalize hover:bg-gray-200 pl-4 py-3 rounded-b-md font-semibold text-yellow-700"> <!-- Logout -->{{$t('logout')}} </router-link>            
                                    </div>                            
                                </ul>
                            </div>
                        </div>
                    </div>

                    <button @click="showMenu = !showMenu" class="block lg:hidden">
                        <svg class="w-7 flex items-center z-50 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd">
                        </path></svg> 
                    </button>
                </div>
            </div>
        </header>

        <!-- ~~~~~~~~~~~~~~~responsive header list -->

        <div>
            <Transition name="slide" class="fixed inset-y-0 right-0 w-full h-full font-nunito z-50">
                <div v-if="showMenu" class="flex justify-end w-full">
                    <div @click="showMenu = !showMenu" class="opacity-25 fixed inset-0 z-20 bg-black"></div>
                    <div class="border w-6/12 bg-white rounded-l-md z-30">
                        <div class="p-4 space-y-5 flex flex-col">
                            <div class="flex relative">
                                <button type="button" @click="showMenu = !showMenu" class="absolute right-0 w-10 transform hover:-translate-1 hover:scale-110 transition duration-100">
                                    <svg class="w-6 h-6 text-red-600 flex justify-end" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" ><path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" /></svg>
                                </button>
                                <label class="flex flex-col space-y-6">
                                    <!-- <router-link :to="'/'+link.link" v-for="(link, i) in headerLinks" :key="i" class="text-sm my-auto font-semibold rounded" :class="{[link.class]:true}" v-show="link.show" v-html="$t(link.name)"></router-link> -->
                                    <a :href="advSearch" target="_blank"  class="flex items-center text-center justify-center hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-s')}}</a>

                                  
                                    <a :href="employerUrl" target="_blank" class="flex items-center text-center justify-center hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-e')}}</a>
                                 
                             
                                    <a :href="jobSeekerUrl" target="_blank" class="flex items-center text-center justify-center hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-js')}}</a>
                                   
                                    <a :href="titpUrl" target="_blank" class="flex items-center text-center justify-center hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('TITP')}}</a>
                                   
                                    <a :href="outSourcingrUrl" target="_blank" class="flex items-center text-center justify-center hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-ot')}}</a>
                                    
                                    <a :href="jobFairUrl" target="_blank" class="flex items-center text-center justify-center hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-jf')}}</a>
                                   
                                    <a :href="advertisementsUrl" target="_blank" class="flex items-center text-center justify-center hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-a')}}</a>
                                    
                                    <a :href="relocationUrl" target="_blank" class="flex items-center text-center justify-center hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-rs')}}</a>
                                    
                                    <a :href="dashboardHomeUrl" target="_blank" class="flex items-center text-center justify-center hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-d')}}</a>
                                   
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
        <!-- ~~~~~~~~~~~~~~~~~~ -->
        <div class="" v-if="$store.getters.showChangePasswordModel">
            <ChangePasswordModel/>
        </div>

        <div class="w-full h-full bg-gray-800 bg-opacity-90 top-0 overflow-y-auto overflow-x-hidden fixed sticky-0" id="chec-div">
            <div class="w-full absolute z-10 right-0 h-full overflow-x-hidden transform translate-x-0 transition ease-in-out duration-700" id="notification">
                <div class="2xl:w-4/12 bg-gray-50 h-screen overflow-y-auto p-8 absolute right-0">
                    <div class="flex items-center justify-between">
                        <p tabindex="0" class="focus:outline-none text-2xl font-semibold leading-6 text-gray-800">
                        <!-- Notifications -->{{$t('sd-cd13-title')}}
                        </p>
                        <div class="flex justify-end items-center">
                            <button @click="markAllSeen" class="text-xs font-bold text-red-500 underline p-4 bg-gray-200 hover:bg-gray-300">
                             Mark All Seen 
                            </button>

                            <button role="button" aria-label="close modal" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 rounded-md cursor-pointer" @click="bellNotificationHandler(false)">
                            <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/notification_1-svg1.svg" alt="icon" />
                           
                        </button>
                        </div>
                        
                    </div> 

                    <div v-for="(message, i) in bellNotifications.messages" :key="i">
                        <!-- Favourite -->
                        <div class="w-full p-3 mt-8 bg-white rounded flex" v-if="message.type == 'fav'">
                            <div tabindex="0" aria-label="heart icon" role="img" class="focus:outline-none w-8 h-8 border rounded-full border-gray-200 flex items-center justify-center">
                                <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/notification_1-svg2.svg"  alt="icon"/>
                            </div>
                            <div class="pl-3">
                                <p tabindex="0" class="focus:outline-none text-sm leading-none"><span class="text-indigo-700" v-html="message.message"/></p>
                                <p tabindex="0" class="focus:outline-none text-xs leading-3 pt-1 text-gray-500" v-html="message.created_at"/>
                            </div>
                        </div>
                        <!-- Add -->
                        <div class="w-full p-3 mt-4 bg-white rounded shadow flex flex-shrink-0" v-if="message.type == 'add'">
                            <div tabindex="0" aria-label="group icon" role="img" class="focus:outline-none w-8 h-8 border rounded-full border-gray-200 flex flex-shrink-0 items-center justify-center">
                                <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/notification_1-svg3.svg"  alt="icon"/>
                                
                            </div>
                            <div class="pl-3 w-full">
                                <div class="flex items-center justify-between w-full">
                                    <p tabindex="0" class="focus:outline-none text-sm leading-none"><span class="text-indigo-700" v-html="message.message"/></p>
                                    <div tabindex="0" aria-label="close icon" role="button" class="focus:outline-none cursor-pointer">
                                        <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/notification_1-svg4.svg"  alt="icon"/>
                                    </div>
                                </div>
                                <p tabindex="0" class="focus:outline-none text-xs leading-3 pt-1 text-gray-500" v-html="message.created_at"/>
                            </div>
                        </div>
                        <!-- Info -->
                        <div class="w-full p-3 mt-4 bg-white rounded flex" v-if="message.type == 'info'">
                            <div tabindex="0" aria-label="post icon" role="img" class="focus:outline-none w-8 h-8 border rounded-full border-gray-200 flex items-center justify-center">
                                <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/notification_1-svg5.svg"  alt="icon"/>
                            </div>
                            <div class="pl-3" v-if="message.link">
                                <a :href="prefix + message.link" >
                                    <p tabindex="0" class="focus:outline-none text-sm leading-none"><span class="text-indigo-700" v-html="message.message"/></p>
                                    <p tabindex="0" class="focus:outline-none text-xs leading-3 pt-1 text-gray-500" v-html="message.created_at"/>
                                </a>
                            </div>
                            <div class="pl-3" v-else>
                                <p tabindex="0" class="focus:outline-none text-sm leading-none"><span class="text-indigo-700" v-html="message.message"/></p>
                                <p tabindex="0" class="focus:outline-none text-xs leading-3 pt-1 text-gray-500" v-html="message.created_at"/>
                            </div>
                        </div>
                        <!-- Warning -->
                        <div class="w-full p-3 mt-4 bg-red-100 rounded flex items-center" v-if="message.type == 'warning'">
                            <div tabindex="0" aria-label="storage icon" role="img" class="focus:outline-none w-8 h-8 border rounded-full border-red-200 flex items-center flex-shrink-0 justify-center">
                                <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/notification_1-svg6.svg"  alt="icon"/>
                            </div>
                            <div class="pl-3 w-full flex items-center justify-between">
                                <p tabindex="0" class="focus:outline-none text-sm leading-none text-red-700" v-html="message.message"/>
                                <p tabindex="0" class="focus:outline-none text-xs leading-3 pt-1 text-gray-500" v-html="message.created_at"/>
                            </div>

                        </div>
                        <!-- Success -->
                        <div class="w-full p-3 mt-8 bg-green-100 rounded flex items-center" v-if="message.type == 'success'">
                            <div tabindex="0" aria-label="success icon" role="img" class="focus:outline-none w-8 h-8 border rounded-full border-green-200 flex flex-shrink-0 items-center justify-center">
                                <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/notification_1-svg11.svg"  alt="icon"/>
                            </div>
                            <div class="pl-3 w-full">
                                <div class="flex items-center justify-between">
                                    <p tabindex="0" class="focus:outline-none text-sm leading-none text-green-700" v-html="message.message"/>
                                    <p tabindex="0" class="focus:outline-none focus:text-indigo-600 text-xs leading-3 underline cursor-pointer text-green-700" v-html="message.created_at"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div class="flex items-center justiyf-between">
                        <hr class="w-full">
                        <p tabindex="0" class="focus:outline-none text-sm flex flex-shrink-0 leading-normal px-3 py-16 text-gray-500">Thats it for now :)</p>
                        <hr class="w-full">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { isEmpty } from 'lodash'
import { mapGetters } from 'vuex'
import { mapState } from 'vuex'
import { mapActions } from 'vuex'
import ChangePasswordModel from '../ChangePasswordModel.vue'
import localforage from 'localforage'

export default {  
  data: function() {
    return {
        dropIt: false,   
        jiipaDropIt: false,
        showMenu:false,
        prefix: process.env.BASE_URL,
        jiipaClientUrl: process.env.VUE_APP_JIIPA_URL,
        jiipaAdminUrl: process.env.VUE_APP_JIIPA_ADMIN_URL,
        sekaiIchi: process.env.VUE_APP_SEKAI_ICHI_URL + 'product.php',
        recruitUrl: process.env.VUE_APP_CLIENT_URL,
        prefix: (window.location.host.includes('dev.')) ? '/sma/' : "/",
        locale: localStorage.getItem("localforage/smartrecruitadmin/locale") ? localStorage.getItem("localforage/smartrecruitadmin/locale").replace(/['"]+/g, '') : 'en',
        bellNotifications: this.$store.getters['auth/user']['bellNotifications'],
        userData: this.$store.getters['auth/user']['data'],
        intervalId: "",
        languages:[],
        advSearch: process.env.VUE_APP_CLIENT_URL + 'advanced-search/job',
        employerUrl: process.env.VUE_APP_CLIENT_URL + 'employer',
        jobSeekerUrl: process.env.VUE_APP_CLIENT_URL + 'job-seeker',
        titpUrl: process.env.VUE_APP_CLIENT_URL + 'titp?filter=job&titp=1',
        outSourcingrUrl: process.env.VUE_APP_CLIENT_URL + 'outsourcing-tender',
        jobFairUrl: process.env.VUE_APP_CLIENT_URL + 'job-fair',
        advertisementsUrl: process.env.VUE_APP_CLIENT_URL + 'advertisements',
        relocationUrl: process.env.VUE_APP_CLIENT_URL + 'relocation-service',
        dashboardHomeUrl: process.env.VUE_APP_CLIENT_URL + 'dashboard-home',
        signUp: process.env.VUE_APP_CLIENT_URL + 'select-role',
    }
},
components: {
    ChangePasswordModel,
},
computed: {
    ...mapState([
      'showChangePasswordModel',
      'hideChangePasswordModel',
      ]),
    ...mapGetters({
        user: 'auth/user',
        // headerLinks: 'getMainHeaderLinks',
        getGeneral: 'footer/getGeneral',

  })
}, 
methods: {
    ...mapActions({
        markNotificationsAsSeen: 'auth/markNotificationsAsSeen',
        languagesList:'languagesList'
        //fetchGeneral: 'footer/fetchGeneral',

    }),
    // fetchBaseUrl(){
    //     this.fetchGeneral().then((response)=>{
    //         let generalLength = this.getGeneral.length
    //         if(this.headerLinks[0].link == 'search'){
    //             this.headerLinks[0].link = this.getGeneral[generalLength-1].BaseUrl +'search';
    //         }
    //         if(this.headerLinks[1].link == 'employer'){
    //             this.headerLinks[1].link = this.getGeneral[generalLength-1].BaseUrl +'employer';
    //         }
    //         if(this.headerLinks[2].link == 'job-seeker'){
    //             this.headerLinks[2].link = this.getGeneral[generalLength-1].BaseUrl +'job-seeker';
    //         }
    //         if(this.headerLinks[3].link == 'jtitp'){
    //             this.headerLinks[3].link = this.getGeneral[generalLength-1].BaseUrl +'jtitp';
    //         }
    //         if(this.headerLinks[4].link == 'outsourcing-tender'){
    //             this.headerLinks[4].link = this.getGeneral[generalLength-1].BaseUrl +'outsourcing-tender';
    //         }
    //         if(this.headerLinks[5].link == 'job-fair'){
    //             this.headerLinks[5].link = this.getGeneral[generalLength-1].BaseUrl +'job-fair';
    //         }
    //         if(this.headerLinks[6].link == 'advertisements'){
    //             this.headerLinks[6].link = this.getGeneral[generalLength-1].BaseUrl +'advertisements';
    //         }
    //         if(this.headerLinks[7].link == 'relocation-service'){
    //             this.headerLinks[7].link = this.getGeneral[generalLength-1].BaseUrl +'relocation-service';
    //         }
    //         if(this.headerLinks[8].link == 'dashboard-home'){
    //             this.headerLinks[8].link = this.getGeneral[generalLength-1].BaseUrl +'dashboard-home';
    //         }
    //         if(this.headerLinks[9].link == 'select-role'){
    //             this.headerLinks[9].link = this.getGeneral[generalLength-1].BaseUrl +'select-role';
    //         }
            
    //     })
    // },
    toggleDropIt() {
        this.dropIt = !this.dropIt;
        if (this.dropIt) {
            this.disableScroll();
        } else {
            this.enableScroll();
        }
    },
    closeDropIt() {
        this.dropIt = false;
        this.enableScroll();
    },
    disableScroll() {
        document.body.style.overflow = 'hidden';
    },
    enableScroll() {
        document.body.style.overflow = '';
    },
    markAllSeen() {
        let vm = this
        this.markNotificationsAsSeen().then((resp) => {
            vm.bellNotificationHandler()
        })
    },
    bellNotificationHandler(flag) {    
        let notification = document.getElementById("notification");
        let checdiv = document.getElementById("chec-div");
        let flag3 = flag || false;
        if (!flag3) {
            notification.classList.add("translate-x-full");
            notification.classList.remove("translate-x-0");
            setTimeout(function () {
                checdiv.classList.add("hidden");
            }, 500);
            flag3 = true;
        } else {
            setTimeout(function () {
                notification.classList.remove("translate-x-full");
                notification.classList.add("translate-x-0");
            }, 500);
            if (this.bellNotifications.count <= 0) {
                return false
            }    
            checdiv.classList.remove("hidden");
            flag3 = false;
            this.notiHide = false
        }
    },
    closeDeleteModel(){
      this.showMenu = false
  },
    
  logout() {
     const payload = {
            'email' : localStorage.getItem('email'),
            'password' : localStorage.getItem('password'),
            'keepMeLoggedIn' : localStorage.getItem('keepMeLoggedIn'),
        }
        localStorage.removeItem('localforage/smartrecruitadmin/authtoken') ? localStorage.removeItem('localforage/smartrecruitadmin/authtoken') : '';
        this.$store.dispatch('auth/logout',payload).then(() => {
        this.$router.replace({ name: 'Sign-In' })
    })
  },
  getUser() {
        clearInterval(this.intervalId);
        if (this.userData) {
            if (this.userData.profileImageUrl) {
                this.$store.state.myprofile.img = this.userData.profileImageUrl;
            } else {
                this.$store.state.myprofile.img;
            }
        } else {
            this.$store.state.myprofile.img;
        }
    },
    getLanguages(){
        try{
            clearInterval(this.intervalId);
            this.languagesList().then((res) => {
                this.languages = res.data;
            });
        }catch(error){
            console.log('e',error);
        }
    },
languageHandler() {
        if (isEmpty(this.locale)){
            return false;
        }else{
            this.$store.commit('changeLanguageSlug', this.locale);
        }
        return false;
    },
    
  
},  

mounted() {
    //this.fetchBaseUrl();
    this.getUser();
    this.getLanguages();
    this.$store.state.myprofile;
    let vm = this
    this.$store.commit('HANDLE_MAIN_HEADER_BASED_ON_AUTH')

    let currentLocale = 'en'
    if(localforage.getItem('localforage/smartrecruitadmin/locale') == null){
        this.locale = currentLocale || 'en';
        localforage.setItem('locale', this.locale);
    }

    this.$store.commit('isAuthenticated', false)
    this.bellNotificationHandler(false);
},
};
</script>

<style>
.slide-enter-active {
  transition: 0.7s;
}
.slide-leave-active{
  transition: 0.6s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}

</style>
