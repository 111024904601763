export default [
{
	path: '/feedback-reviews',
	component: () => import('../components/FeedbackReviews.vue'),
	name: 'feedback-reviews',
	
	meta: {
		guest: false,
		needsAuth: true
	}
},

]
