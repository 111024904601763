<template>
    <div>  
        <div class="my-28"> 
            <div class="container mx-auto my-10">
                <div class="flex justify-center">
                    <label class="font-bold uppercase text-2xl border-b-2 pb-2 border-red-600"><!-- Reviews -->{{$t('sec11-title')}} </label>
                </div>
            </div>

            <div class="container mx-auto">
                <div class="flex lg:flex-wrap mx-2 lg:mx-10 shadow-2xl">
                    <div class="w-full">
                        <ul class="flex mb-0 list-none lg:flex-wrap pt-1 border-b-2 border-red-600 lg:flex-row overflow-auto">
                           <li class="flex-auto text-center">
                                <a class="text-base px-5 py-3 block leading-normal cursor-pointer" v-on:click="toggleTabs(1, 'employer')" v-bind:class="{'text-black text-base w-36 lg:w-full': openTab !== 1, 'font-bold text-base text-white bg-bgGray rounded-t-md shadow-inner w-36 lg:w-full': openTab === 1}"><!-- Employers -->{{$t('sec6-gb1-text')}}</a>
                            </li>
                            <li class="flex-auto text-center cursor-pointer">
                                <a class="text-base px-5 py-3 block leading-normal" v-on:click="toggleTabs(2, 'job-seekers')" v-bind:class="{'text-black text-base w-36 lg:w-full': openTab !== 2, 'font-bold text-base text-white bg-bgGray rounded-t-md shadow-inner w-36 lg:w-full': openTab === 2}">
                                    <!-- Job Seekers -->{{$t('sec6-gb2-text')}}
                                </a>
                            </li>
                            <li class="flex-auto text-center cursor-pointer">
                                <a class="text-base px-5 py-3 block leading-normal" v-on:click="toggleTabs(3, 'job-fairs')" v-bind:class="{'text-black text-base w-36 lg:w-full': openTab !== 3, 'font-bold text-base text-white bg-bgGray rounded-t-md shadow-inner w-36 lg:w-full': openTab === 3}">
                                    <!-- Job Fairs -->{{$t('sec11-tab3-title')}}
                                </a>
                            </li>
                            <li class="flex-auto text-center cursor-pointer">
                                <a class="text-base px-5 py-3 block leading-normal" v-on:click="toggleTabs(4, 'promotions')" v-bind:class="{'text-black text-base w-36 lg:w-full': openTab !== 4, 'font-bold text-base text-white bg-bgGray rounded-t-md shadow-inner w-36 lg:w-full': openTab === 4}">
                                    <!-- Promotions -->{{$t('sec11-tab4-title')}}
                                </a>
                            </li>
                            <li class="flex-auto text-center cursor-pointer">
                                <a class="text-base px-5 py-3 block leading-normal" v-on:click="toggleTabs(5, 'relocation-service')" v-bind:class="{'text-black text-base w-36 lg:w-full': openTab !== 5, 'font-bold text-base text-white bg-bgGray rounded-t-md shadow-inner w-36 lg:w-full': openTab === 5}">
                                <!--  Relocation Service -->{{$t('head-rs')}}
                                </a>
                            </li>
                            <li class="flex-auto text-center cursor-pointer">
                                <a class="text-base px-5 py-3 block leading-normal" v-on:click="toggleTabs(6)" v-bind:class="{'text-black text-base w-36 lg:w-full': openTab !== 6, 'font-bold text-base text-white bg-bgGray rounded-t-md shadow-inner w-36 lg:w-full': openTab === 6}">
                                 <!-- General -->{{$t('sec11-tab5-title')}}
                                </a>
                            </li>
                        </ul>
                        <div class="relative flex flex-col min-w-0 break-words bg-white w-full">
                            <div class="md:px-4 py-5 flex-auto">
                                <div class="tab-content tab-space">
                                    <div class="container mx-auto relative">
                                        <div v-if="getSlideLoader">
                                            <FeedbackLoader/>
                                        </div>

                                        <div class="card" >
                                            <div v-if="!getFeedbackPublishList || getFeedbackPublishList.length === 0" 
                                            class="flex justify-center items-center h-20 text-gray-600">
                                                <!-- No data availble -->{{ $t('no-data-found') }}
                                            </div>
                                            <div v-else :key="getFeedbackPublishList">
                                                <!-- :autoplayInterval="3000" -->
                                                <Carousel :value="getFeedbackPublishList" :num-visible="3" :num-scroll="1" :responsive-options="responsiveOptions" :showIndicators="false" >
                                                <template #item="{data}">
                                                    <div class="m-4">
                                                        <div class="bg-white rounded-lg shadow-md p-4 mb-4 border">
                                                            <div class="md:flex items-center mb-4">
                                                                <img :src="data.profileImageUrl ? data.profileImageUrl : '/images/user.png'" alt="Profile Photo" class="w-12 h-12 rounded-full mr-4">
                                                                <div class="flex flex-col space-y-1">
                                                                    <h2 class="text-sm font-semibold">
                                                                    {{ data.first_name }} {{ data.last_name }}</h2>
                                                                    <div class="flex">
                                                                        <svg v-for="n in parseInt(data.star)" class="fill-current text-yellow-500" xmlns="http://www.w3.org/2000/
                                                                        svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#000000"><path 
                                                                        d="M0 0h24v24H0V0z" fill="none"/><path d="M12 17.27L18.18 21l-1.64-7.03L22 
                                                                        9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"/></svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="mb-4 h-40 overflow-auto border rounded-md">
                                                                <p class="text-gray-800 p-3 tesxt-sm">{{ data.message }}</p>
                                                            </div>

                                                            <div class="flex justify-between text-xs">
                                                                <h4 class="text-right capitalize underline text-black truncate">{{data.category}}</h4>
                                                                <h4 class="text-right">{{ formatDate(data.created_at) }}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                </Carousel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapMutations } from 'vuex'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import FeedbackLoader from './partials/FeedbackLoader.vue'

import Carousel from 'primevue/carousel';

export default {
    data() {
        return {
            openTab: 6,
            first_name:'',
            last_name:'',
            email:'',
            message:'',
            profileImageUrl:'',
            time:'',
            date:'',            
            star:'',
            feedbackList: [],
            
            responsiveOptions:[
                {
                    breakpoint: '2600px',
                    numVisible: 4,
                    numScroll: 1
                },
                {
                    breakpoint: '1499px',
                    numVisible: 3,
                    numScroll: 1
                },
                {
                    breakpoint: '1067px',
                    numVisible: 2,
                    numScroll: 1
                },
                {
                    breakpoint: '575px',
                    numVisible: 1,
                    numScroll: 1
                }
            ],

        }
    },

    components: {
        FeedbackLoader,  
        Carousel,     
    },

    computed: {
        ...mapGetters({
            getFeedbackPublishList: 'feedbackReviews/getFeedbackPublishList',
            getSlideLoader: 'feedbackReviews/getSlideLoader',
        }),    
        ...mapState({
            feedbackReviews: 'feedbackReviews', 
        }),    
    },

    methods: {
        ...mapMutations({
            'slideLoader':'feedbackReviews/slideLoader',
        }),
        ...mapActions({
            fetchFeedbackPublishList: 'feedbackReviews/fetchFeedbackPublishList',            
        }),
        formatDate(dateTimeString) {
            const date = new Date(dateTimeString);
            return date.toISOString().split('T')[0];
        },
        
        toggleTabs(tabNumber, category=""){
            this.openTab = tabNumber;   
            this.$store.commit('feedbackReviews/setFeedbackCategoryList', category);
            this.fetchData()
        },

        fetchData(){
            try{
                this.slideLoader(true)
                this.fetchFeedbackPublishList().then((response) => {
                    if(response){
                        setTimeout(() => {
                            this.slideLoader(false)
                        }, 500);
                    }
                })
                .catch((err) => {
                });
            }catch(error){
                
            }
        },
    },
    
    mounted() {
        this.fetchData();
    },
};
</script> 


<style>
span.p-carousel-prev-icon.pi.pi-chevron-right {
        background: #545454;
        color: #fff;
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    span.p-carousel-prev-icon.pi.pi-chevron-left{
         background: #545454;
         color: #fff;
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

/*~~~~~~~~~~~~~~scrollbarReview classes*/
#scrollbarReview::-webkit-scrollbarReview {
  width: 10px;
}

/* Track */
#scrollbarReview::-webkit-scrollbarReview-track {
  background: #f1f1f1; 
}

/* Handle */
#scrollbarReview::-webkit-scrollbarReview-thumb {
  background: #888; 
}

/* Handle on hover */
#scrollbarReview::-webkit-scrollbarReview-thumb:hover {
  background: #555; 
}
/*~~~~~~~~~~~~~~~~~~~~*/
</style>