export const getContactList = (state) => {
	return state.contactList.data
}
export const getContactListLinks = (state) =>{
    return state.contactList.links;
}
export const getContactListMeta = (state) => {
	return state.contactList.meta
}
export const getShowLoader = (state) => {
      return state.showLoader
}

export const getContactListByID = (state) => {
	return state.contactListByID
}

export const getReplyListByID = (state) => {
	return state.replyListByID
}